<template>
  <abnormal-warning :datas="[7, 'viewCircuitException', 'editCircuitException']"></abnormal-warning>
</template>
<script>
import AbnormalWarning from '@/components/threshold/AbnormalWarning.vue'
export default {
  components: {
    AbnormalWarning,
  },
  data() {
    return {}
  },
}
</script>
